@import '../../../assets/css/utils/utilities';

.rto-data-table {
  margin-bottom: 30px;
  text-align: left;

  // Override Carbon Select style
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  // Style override for DataTable Header
  .bx--data-table-header {
    padding: 1rem 1rem 1.5rem;
  }

  // Style override for DataTable Container.
  .bx--data-table-container {
    min-width: 100%;
  }

  // Style override for DataTable TableToolbar
  .bx--table-toolbar {
    flex-flow: row wrap;
    height: auto;
    padding: 1rem;
  }

  .bx--data-table-header__description {
    line-height: 1.5rem;
  }

  .bx--toolbar-search-container-expandable {
    background-color: $color-white;
  }

  // For centering the magnifying icon
  .bx--search {
    position: static;
  }

  .bx--toolbar-action:focus:not([disabled]),
  .bx--toolbar-action:active:not([disabled]) {
    outline: none!important;
  }

  .bx--search-input:focus {
    outline: none!important;
  }

  .bx--search-input:not(:placeholder-shown) {
    background-color: transparent!important;
  }

  .bx--search-close::before {
    content: none;
  }

  &__toolbar-content {
    height: auto!important;
    position: relative;

    @media screen and (max-width: 375px) {
      justify-content: flex-start !important;
    }

    @media (max-width: 950px) {
      height: 3rem;
    }

  }

  .filters-container {
    z-index: 10;
  }

  &__wrapper {
    overflow-x: scroll;
  }

  &__filters {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    .bx--select-input__wrapper {
      background-color: $color-white;
    }

    // Remove flex for filter item wrappers.
    .bx--form-item {
      flex: 0;
      margin-bottom: 15px;
      margin-right: 20px;
    }

    .bx--form-item:only-child {
      margin: 0;
    }

    .bx--select-input {
      padding-left: 0.8rem!important;
    }
  }

  &__filter-clear {
    margin-bottom: 15px;
  }

  &__refresh {
    display: block;
    margin-left: auto;
    color: #0f62fe;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }

  .rto-button {
    width: 150px;
    padding-right: 25px;
  }
}

.bx--data-table td {
  &.rto-data-table__cell--awaiting {
    color: $color-black;
    background-color: $color-ivory;
  }

  &.rto-data-table__cell--past {
    color: rgba($color-dim-gray, 0.6)!important;
    background-color: rgba($color-dim-gray, 0.2)!important;
  }
}

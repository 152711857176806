@import '../../../assets/css/utils/utilities';

.form,
.request-form {
  &__fieldset {
    margin-top: 1rem;
    border-top: 1px solid #ccc;
    &.required-field {
      legend {
        &::after {
          content: '*';
          display: inline-block;
          color: red;
        }
      }
    }
  }

  legend {
    width: 100%;
    padding: 0.5rem 0;
  }

  label {
    &.required-field {
      &::after {
        content: '*';
        display: inline-block;
        color: red;
      }
    }
  }

  legend,
  label {
    font-family: $font-bold;
    color: $color-nobel;
  }

  &__explainer {
    margin-top: 1rem;
    max-width: 530px;
    min-height: auto !important;
    border: 1px dotted $color-gainsboro;
    border-radius: 6px;
    background-color: $color-carla !important;
    color: #222;

    ol,
    ul {
      margin-top: 1rem;
      padding-left: 1.5rem;
    }

    .bx--list__item {
      padding-left: 0.7rem;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }

    &__list--dates {
      list-style: disc;
      .bx--list__item {
        list-style-type: disc;
        padding-left: 0;
        &::before {
          display: none !important;
        }
      }
    }

    &__list--two-cols {
      column-count: 2;
    }
  }

  &__helper-text {
    margin-bottom: 1rem;
    font-size: 0.75rem;
    opacity: 0.6;
  }

  &__btns {
    button {
      display: flex;
      text-transform: uppercase;
      max-width: 100% !important;
      transition: transform 0.3s ease;
      transform-origin: 0;
      white-space: nowrap;

      @media screen and (max-width: 375px) {
        white-space: normal;
      }

      &:hover {
        transform: scale(1.02);
      }

      & + button {
        margin-top: 0.5rem;
      }

      &.bx--btn--disabled {
        border: none;
        transform: scale(1);
        background: #bebebe !important;
      }


    }
  }

  &__request-types-group {
    @media (max-width: 478px) {
      flex-flow: column;
      width: 100%;
    }
  }

  &__request-types {
    @media (max-width: 478px) {
      width: 100%;
      margin-right: 0 !important;
      margin-bottom: 20px;
    }

    &--disabled {
      position: relative;
      cursor: pointer;
      &::before {
        content: 'Sick/Emergency days can only be submitted either the day before, or the morning of, the specific date, and cannot conflict with any other pending or approved dates.';
        font-size: 0.75rem;
        position: absolute;
        opacity: 0;
        pointer-events: none;
        width: max-content;
        max-width: 13rem;
        height: auto;
        padding: 1rem;
        border-radius: 5px;
        color: #fff;
        background-color: #000;
        z-index: 10000;
      }
      &:hover {
        &::before {
          opacity: 1;
          bottom: 0;
          left: 50%;
          clip: auto;
          margin: auto;
          overflow: visible;
          transform: translate(-20%, calc(100% + 0.5rem));
        }
      }
    }
  }
}

.date-pickers {
  display: inline-flex;

  @media (max-width: 478px) {
    flex-flow: row wrap;
    width: 100%;
  }

  & > div {
    margin-right: 1rem;

    @media (max-width: 478px) {
      flex-shrink: 0;
      margin-bottom: 20px;

      &:last-of-type {
        margin-right: 0;
        flex-grow: 1;
      }
    }
  }
}

.react-datepicker__input-container {
  input {
    border: 0;
    border-bottom: 1px solid #8c8c8c;
    width: 100%;
    height: 2.5rem;
    padding: 0 3rem 0 1rem;
    font-size: 0.75rem;
    background-color: #f3f3f3;
    color: #171717;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

#period-selector {
  &.bx--dropdown {
    min-width: 150px;
  }
}

.bx--list-box {
  &__label,
  &__menu-item {
    font-size: 0.75rem !important;
  }
}

.bx-tile {
  min-height: 2rem;
}
.bx--text-input__field-wrapper,
.bx--text-area__wrapper {
  width: 100%;
}

.select {
  &__control {
    border: none !important;
    border-bottom: 1px solid #8c8c8c !important;
    border-radius: 0 !important;
    background-color: #f3f3f3 !important;
  }
  &__placeholder {
    font-size: 0.75rem !important;
  }
}

.bx--radio-button__label {
  .request-form__request-types-group & {
    justify-content: start !important;
  }
}

@import '../../../assets/css/utils/utilities';

.badge {
  color: inherit;
  border-radius: 4px;
  padding: 3px 5px;
  white-space: nowrap;

  @media (max-width: 375px) {
    white-space: normal;
  }

  &--info {
    background-color: $color-whisper;
  }

  &--success {
    background-color: $color-blue-romance;
  }

  &--warning {
    background-color: $color-canary;
  }

  &--danger {
    background-color: $color-misty-rose;
  }
}

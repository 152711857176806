@import '../../../assets/css/utils/utilities';

.confirm {
  padding: 3px;
  text-align: center;

  &__text {
    margin-bottom: 5px;
  }
}

.confirm__option {
  color: $color-primary;
  cursor: pointer;
  white-space: nowrap;
  margin-left: 2px;
  padding: 4px;

  &:hover {
    text-decoration: none;
    font-weight: bold;
    background: $color-whisper;
  }

  &--yes {
    color: $color-malachite;
  }

  &--no {
    color: $color-brick-red;
  }
}

.request-action {
  white-space: nowrap;
  margin-bottom: 10px;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }

  &--email {
    color: $color-curious-blue;
  }

  &--cancel {
    color: $color-geraldine;
  }

  &--hidden {
    display: none;
  }
}

@import '../../../assets/css/utils/variables';

.navigation {
  border-bottom: 2px solid $color-nobel;
  margin-bottom: 10px;

  @media (min-width: 950px) {
    margin-bottom: 30px;
  }

  ul {
    @media (min-width: 950px) {
      display: flex;
      flex-wrap: wrap;
    }

    > li {
      margin-right: 15px;
    }
  }

  &__link {
    color: rgba($color-black, 0.25);
    display: block;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 15px;
    text-align: left;

    &:hover {
      color: $color-black;
      text-decoration: none;
    }

    &:last-of-type {
      display: inline-block;
    }

    @media (min-width: 768px) {
      display: inline-block;
      margin-bottom: 5px;

      &:not(:first-of-type) {
        margin-left: 20px;
      }
    }

    @media (min-width: 950px) {
      font-size: 1.5rem;
      line-height: 1.5;
      margin-bottom: 0;
    }
  }

  &__link--active {
    color: $color-black;
  }

  &__link-tooltip {
    margin-left: 20px;
  }
}

@import '../../../assets/css/utils/utilities';

.header {
  position: relative;
  width: 100%;
  height: auto;
  padding: 1em;
  background: #333;
  color: #fff;

  &:after {
    content: '';
    position: absolute;
    bottom: -12px;
    left: 0;
    width: 100%;
    height: 10px;
    background: #999;
    z-index: 0;
  }

  &--qa:after {
    background: #ffbf00;
  }

  &--staging:after {
    background: #39ff14;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: space-between;
    height: 100%;
    flex-flow: column;

    @media (min-width: 768px) {
      flex-flow: row wrap;
      align-items: center;
    }
  }
}

.branding {
  margin-bottom: 20px;

  &__logo {
    font-family: 'Times New Roman', Times, serif;
    text-transform: uppercase;
  }

  &__title {
    margin: 0;
    font-size: 1.75rem;
  }
}

.logged {
  text-align: left;

  @media (min-width: 768px) {
    text-align: right;
  }

  &__email {
    display: block;
    font-size: 0.8em;
    color: $color-nobel;
  }

  &__as {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background: $color-carla;
    color: $color-primary;
  }

  &__as-text {
    margin-right: 10px;
  }

  &__logout {
    color: $color-white;
    display: inline-block;
    margin-top: 10px;
    background: transparent;
    border: 0;
    cursor: pointer;

    &:hover {
      color: $color-nobel;
      text-decoration: none;
    }
  }
}

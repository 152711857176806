.rto-data-table__filters {
  margin-bottom: 20px;

  .rto-data-table__filter-datepickers {
    display: flex;
    gap: 9px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    margin-right: 20px;

    .rto-data-table__filter-datepicker {
      display: flex;
      width: 125px;
      gap: 9px;
      justify-content: flex-start;
      align-items: center;

      .react-datepicker-wrapper {
        width: 100%;
      }

      #dropdown-from_date, #dropdown-to_date {
        background-color: #ffffff;
        width: 100%;
        padding: 0 8px;
      }

      .bx--label {
        margin-bottom: 0;
      }
    }
  }

  .rto-data-table__filter-clear {
    margin-right: 20px;
  }

  .rto-data-table__filter-csv {
    margin-right: 20px;
  }

  .rto-data-table__filter-csv:hover {
    text-decoration: none;
  }

  #select-division, #select-department {
    width: 150px;
  }
}

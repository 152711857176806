/**
 * 
 * Project webfonts.
 *
 */

/* https://css-tricks.com/snippets/css/system-font-stack/ */
@font-face {
	font-family: system;
	font-style: normal;
	font-weight: 300;
	src: local('.SFNSText-Light'), local('.HelveticaNeueDeskInterface-Light'),
		local('.LucidaGrandeUI'), local('Ubuntu Light'), local('Segoe UI Light'),
		local('Roboto-Light'), local('DroidSans'), local('Tahoma');
}

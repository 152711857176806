.calendar {
  margin-bottom: 2em;

  &__iframe {
    position: relative;
    &::before {
      content: '';
      display: block;
      padding-bottom: calc(100% / (16 / 9));
    }

    > :first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }
}

@import '../../../assets/css/utils/utilities';

.request-info-details {
  &__heading {
    color: $color-nobel;
    margin: 10px 0 20px;
    font-weight: bold;
    font-size: 1rem;
  }

  &__table {
    margin-bottom: 50px;
  }

  &__row-header {
    border: 1px solid $color-whisper;
    width: 200px!important;

    @media (max-width: 950px) {
      width: 100px !important;
    }
  }

  &__text-status--viewed {
    color: rgba($color-dim-gray, 0.6);
  }

  &__text-status--rejected {
    color: $color-brick-red;
  }

  &__text-status--approved {
    color: $color-malachite;
  }

  &__status-comment {
    color: $color-pacific-blue;
    display: block;
    font-style: italic;
    padding: 5px 10px 5px 20px;
  }

}

.alert {
  position: relative;
  padding: 1.5rem;
  margin-bottom: 2rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &__wrapper {
    display: flex;
    align-items: center;

    div:first-child {
      margin-right: 10px;
    }

    p {
      margin: 0;
      & + p {
        margin-top: 1rem;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  &--success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &--danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &--warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  &--info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
  }

  &--secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
  }
}

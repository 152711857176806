/**
 *
 * Project-wide styles
 *
 */

// :root {
//   --primary-color: $color-curious-blue;
//   --warning: $color-dolly;
//   --success: $color-silver-tree;
//   --danger: $color-persian-red;
// }
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $font-body;
  font-size: 1rem;
  // background: #f5f5f5;
  color: #333;
}

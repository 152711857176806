/**
 *
 * Typography rules.
 *
 */

a {
  color: $color-curious-blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

strong,
b {
  font-family: $font-heading;
}

em,
i {
  font-family: $font-italic;
}

u {
  text-decoration: underline;
}

input,
textarea,
select,
button {
  font-family: $font-body;
}

h1,
.h1 {
  font-size: $h1-font-size;
}
h2,
.h2 {
  font-size: $h2-font-size;
}
h3,
.h3 {
  font-size: $h3-font-size;
}
h4,
.h4 {
  font-size: $h4-font-size;
}
h5,
.h5 {
  font-size: $h5-font-size;
}
h6,
.h6 {
  font-size: $h6-font-size;
}

p + p {
  margin-top: 1rem !important;
}

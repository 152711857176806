.no-discipline-lead {
  .bx--tooltip__trigger::after {
    max-width: 16rem!important;
    padding: 0.5rem 1rem!important;
  }
}

.loading-animation {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem auto;
}

// Font Family
$font-body: 'Arial', sans-serif, system;
$font-heading: 'Arial Black', 'Arial', sans-serif, system;
$font-bold: $font-heading;
$font-italic: 'Arial Italic', 'Arial', sans-serif, system;

// Font Sizings
$base-font-size: 16px;

$h1-font-size: 2.5rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.75rem !default;
$h4-font-size: 1.5rem !default;
$h5-font-size: 1.25rem !default;
$h6-font-size: 1rem !default;

/**
 * Breakpoints
 */
$bp-sm: 360px;
$bp-md: 640px;
$bp-lg: 1000px;
$bp-xlg: 1400px;

/** Max Breakpoints */
$bp-mxsm: 639px;
$bp-mxmd: 999px;
$bp-mxlg: 1399px;

$gutter-widths: (
  'mobile': 8px,
  'tablet': 24px,
  'desktop': 32px
) !default;

$page-gutter-widths: (
  'mobile': 20px,
  'tablet': 48px,
  'desktop': 80px
) !default;

$guide-config: (
  'mobile': 12 map-get($gutter-widths, 'mobile') map-get($page-gutter-widths, 'mobile'),
  'tablet': 12 map-get($gutter-widths, 'tablet') map-get($page-gutter-widths, 'tablet'),
  'desktop': 12 map-get($gutter-widths, 'desktop') map-get($page-gutter-widths, 'desktop')
);

/**
 * Colors
 *
 * These colors are named using
 * https://www.htmlcsscolor.com/
 */
$color-primary: #333333;
$color-black: #000000;
$color-dim-gray: #666666;
$color-gainsboro: #DCDCDC;
$color-nobel: #999999;
$color-white: #ffffff;
$color-whisper: #eeeeee;
$color-white-smoke: #f6f6f6;
$color-emerald: #55bf70;
$color-ocean-green: #50b369;
$color-malachite: #22bf54;
$color-blue-romance: #ccffcb;
$color-cerulean: #0087b0;
$color-curious-blue: #3186c3;
$color-pacific-blue: #009bc9;
$color-bondi-blue: #00a0b0;
$color-sherpa-blue: #00434a;
$color-ivory: #fdfdf4;
$color-carla: #fbffc8;
$color-canary: #fff999;
$color-misty-rose: #ffdcdc;
$color-geraldine: #e37171;
$color-brick-red: #cc333f;

/**
 * Alert Colors
 *
 * These are the border-color of Alerts.
 * By using rgba($color, [alpha]), you can get
 * the background color for alert.
 *
 * Sample: background-color: rgba($color-dolly, 0.5);
 */
$color-dolly: #f7f77b;
$color-silver-tree: #68c47f;
$color-persian-red: #d43333;

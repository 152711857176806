@import '../../../assets/css/utils/utilities';

.approval-page {
  &__header {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0px;
  }
}
